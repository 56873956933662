#root
  > div
  > div
  > nav
  > div
  > div.MuiStack-root.css-j7qwjs
  > a:nth-child(2)
  > div
  > div.MuiListItemText-root.css-1ua2hv8
  > span {
  white-space: normal;
}
